<template>
  <div id="tachoBackground">
        <canvas 
          id="nadelCanvas"
          >
        </canvas>
  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import { mapActions } from "vuex";

export default {
  name: 'TachoRund',

  computed:{
    ...mapGetters([
      'A1Power',
      'powerNorm',
    ]),
  },

  data() {
    return {
      nadelCanvas: null
    }
  },

  mounted() {
    this.nadelCanvas = document.getElementById("nadelCanvas");
    
  },

  watch: {
    // whenever question changes, this function will run
    powerNorm: function (_neu, _alt) {
      this.updateTachoNadel(_neu)
    }
  },

  methods: {
    ...mapActions([
    ]),

    updateTachoNadel: function (_power) {
      var canvaselem = this.nadelCanvas
      var ctx = canvaselem.getContext('2d');
      
      var paddingBottom = 10;
      var paddingTop = 10;
      ctx.clearRect(0, 0, canvaselem.width, canvaselem.height);
      
      ctx.save();
      ctx.beginPath();
      ctx.lineWidth = 5;
      ctx.lineCap = "round";
      ctx.shadowColor = 'black';
      ctx.shadowOffsetX = 10;
      ctx.shadowOffsetY = 5;
      ctx.shadowBlur = 10;
      
      var xs = canvaselem.width/2 - Math.sin(parseFloat(_power) *Math.PI)*(paddingBottom);
      var ys = canvaselem.height-paddingBottom  + Math.cos(parseFloat(_power) *Math.PI)*(paddingBottom);
      ctx.moveTo(xs, ys);
      // Spitze
      var xx = -Math.cos(parseFloat(_power) *Math.PI)*(canvaselem.height-paddingBottom-paddingTop);
      var yy = -Math.sin(parseFloat(_power) *Math.PI)*(canvaselem.height-paddingBottom-paddingTop);
      ctx.lineTo(canvaselem.width/2+xx, canvaselem.height-paddingBottom+yy);
      
      var xe = canvaselem.width/2 + Math.sin(parseFloat(_power) *Math.PI)*(paddingBottom);
      var ye = canvaselem.height-paddingBottom  -Math.cos(parseFloat(_power) *Math.PI)*(paddingBottom);
      ctx.lineTo(xe, ye);
      
      ctx.fillStyle = "#eaff00";
      ctx.fill();
      ctx.restore();
      
      ctx.beginPath();
      ctx.arc(canvaselem.width/2, canvaselem.height-paddingBottom, paddingBottom, 0, 2 * Math.PI);
      ctx.fillStyle = "#eaff00";
      ctx.fill();
      
      ctx.beginPath();
      ctx.arc(canvaselem.width/2, canvaselem.height-paddingBottom, 3, 0, 2 * Math.PI);
      ctx.fillStyle = "black";
      ctx.fill();
    }

  },

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  #tachoBackground {
    width: 30vw;
    height: 20vh;
    position: absolute;
  }

  #nadelCanvas {
    position:absolute;
    bottom: 0;
    width: 40vw; 
    background-size: contain;
    background-image: url('../assets/tacho.png');
  }

    h1 {
    
    font-family: Frutiger, Hevetica, Arial, sans-serif;
    cursor: none;
    font-size: 6vh;
    font-weight: 100;
    text-align: center;
    margin: 1vh 0;

  }

  h2 {
    font-family: Segment, Hevetica, Arial, sans-serif;
    cursor: none;
    font-size: 12vh;
    font-weight: 100;
    text-align: right;
    margin: 1vh 0;
  } 

  .text {
    font-family: Frutiger, Hevetica, Arial, sans-serif;
    cursor: none;
    font-size: 3vh;
    font-weight: 100;
    text-align: right;
    margin: 1vh 0;
  }

</style>

import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store/index'

import axios from 'axios'
import VueAxios from 'vue-axios'
import VueFormulate from '@braid/vue-formulate'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

// https://floating-vue.starpad.dev/guide/component.html#dropdown
import FloatingVue from 'floating-vue'
Vue.use(FloatingVue)
import 'floating-vue/dist/style.css'

import VueToast from 'vue-toast-notification';
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';
// import DynamicForm from 'vue-dynamic-form-component'
// Vue.use(DynamicForm)
Vue.use(VueToast, {
    // One of the options
    position: 'top'
})

const axiosInstance = axios.create({
  baseURL: 'http://www.your-upload-server.com' //TODO
})

Vue.use(VueAxios, axios)
Vue.use(VueFormulate , {
  uploader: axiosInstance,
  uploadUrl: '/upload'
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

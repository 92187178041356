<template>
  <div id="HS4LR" class="LRBG">

    <div class="logoLR"></div>



    <div id="inputView" 
        v-if="view === 'input'">

    
      <el-form 
          ref="form" 
          :model="form" 
          label-width="120px"
          :rules="rules" 
          hide-required-asterisk
          class="formContainer form1"
          id="dataForm"
      >
        <el-form-item label="Vorname:" prop="vorname">
          <el-input v-model="form.playerVorName"></el-input>
        </el-form-item>
        <el-form-item label="Name:" prop="name">
          <el-input v-model="form.playerName"></el-input>
        </el-form-item>
        <el-form-item label="Unternehmen:" prop="unternehmen">
          <el-input v-model="form.companyName"></el-input>
        </el-form-item>
        <el-form-item label="E-Mail:" prop="email">
          <el-input v-model="form.email"></el-input>
        </el-form-item>


<div class="contentRow">
    <div id="clickFieldRow">
      <div @click="showPdf=true" class="clicktext">
        Ich stimme der Datenschutzerklärung zu
      </div>
      <el-checkbox v-model="form.zustimmung" class="zustimmung"></el-checkbox>
    </div>
</div>

<div class="contentRow">
        <div class="buttoncontainer">
          <el-button type="" @click="checkData('form')">Jetzt teilnehmen</el-button>
          <el-button type="danger" @click="resetForm('form')" >Löschen</el-button>
        </div>
      </div>

      </el-form>


      <el-form 
          ref="timeInput" 
          :model="form" 
          label-width="120px"
          :rules="timeRules" 
          hide-required-asterisk
          class="formContainer form2"
          v-if="showTimeInput"

      >
        <el-form-item label="Zeit:" prop="zeit" class="timeInput">
          <el-input placeholder="min" class="zeitInput" v-model="form.zeit.min"></el-input>
          : <el-input placeholder="sec" class="zeitInput" v-model="form.zeit.sec"></el-input>

        </el-form-item>



        <div class="buttoncontainer">
          <el-button type="" @click="sendDataAndWait()">weiter</el-button>
          <el-button type="danger" @click="form.zeit.min = '';form.zeit.sec = ''" >Löschen</el-button>

        </div>
      </el-form>
    </div>



  <!-- ...................................... PDF ........................................ -->
      <div class="pdfContainer"
        v-if="showPdf == true" 
        @click="showPdf=false"
      >
        <iframe       :src="pdfUrl" class="pdfIFrame" />
         <el-button type="" @click="showPdf=false"
          class="OKButton">OK</el-button>
      </div>


<!-- ...................................... HighScore ........................................ -->
    <div id="hsList" 
        v-if="view === 'top10'">

        <TopTen :eventName="eventName" ></TopTen>

         <el-button type="" @click="startInput()" 
          class="startButton">Jetzt teilnehmen</el-button>

    </div>


  </div>
</template>



<script>
  import {mapGetters} from "vuex";
  import { mapActions } from "vuex";
  import TopTen from '@/components/highscore/TopTen.vue'; // @ is an alias to /src
  import {  Form, FormItem } from 'element-ui';

export default {
  name: 'LohmannRauscher',

  components: {
    Form,
    FormItem,
    TopTen
  },

   data () {
    return {
      showTimeInput: false,
      eventName: 'lohmanrauscher',
      view: 'top10',
      pdfUrl: '/infos/DatenschutzhinweisederLohmann.pdf',
      showPdf: false,

      formClean:{
        playerName: "",
        playerVorName: "",
        email: "",
        companyName: "",
        zustimmung: false,
        zeit: {
          min: "",
          sec: "",
        }
      },
      formTest:{
        playerName: "asdf",
        playerVorName: "ich",
        email: "mich@nix.de",
        companyName: "ääää",
        zustimmung: false,
         zeit: {
          min: "",
          sec: "",
        }
      },
      form:{},

      rules: {
          playerName: [
            { required: true, message: 'Please input your name', trigger: 'blur' },
            { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' }
          ],
          playerVorName: [
            { required: true, message: 'Deinen Namen bitte', trigger: 'change' }
          ],
          email: [
            { type: 'email', required: true, message: 'Deine Email bitte', trigger: 'change' }
          ],
          companyName: [
            { required: true, message: 'Deinen Arbeitgeber bitte', trigger: 'change' }
          ],
      },
      timeRules: {},

    }
  },

  computed:{
    ...mapGetters([
    ]),
  },

  created() {
    this.resetForm() 
   // this.getHighScoreListByEvent(this.eventName)
  },

  mounted() {
  },

  methods: {

    ...mapActions([
      'sendDataToServer',
      'showHighscore',
      'getHighScoreListByEvent',
      'setShowHighscore',
    ]),

    startInput: function () {
      this.view = 'input'
      this.resetForm()
    },

    sendDataAndWait: async function () {
      this.form.points = Number(this.form.zeit.min) * 60 + Number(this.form.zeit.sec)
      await this.sendDataToServer(this.form)
      this.view = 'top10'
    },

/*
    clearValidate () {
      this.$refs['dynamic-form'].clearValidate()
    },
    async validate () {
      const valid = await this.$refs['dynamic-form'].validate()
      window.alert(`valid result ====> ${valid}`)
    },
*/
    checkData(formName) {
      console.log(" -> submitForm -> ", formName)
      if (this.form.zustimmung != true) {
          return false;
        }

        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.showTimeInput = true
          } else {
            console.log('error submit!!');
            return false;
          }
        });

        
      },

      resetForm: function () {
         this.form = { ...this.formClean }
       //  this.form = { ...this.formTest }
         this.form.eventName = this.eventName
      },

    
    checkForm: function(currentForm) {
      let firstNameSelected = false;
      if (currentForm.firstname.value == ""){
          document.getElementById('firstname').style.borderColor = '#ff0000';
          document.getElementById('firstname').style.borderStyle = 'solid';
          document.getElementById('firstname').style.borderWidth = '2px';
      }
      else{
          document.getElementById('firstname').style.borderColor = '#ffffff';
          firstNameSelected = true;
      }

      let lastNameSelected = false;
      if (currentForm.lastname.value == ""){
          document.getElementById('lastname').style.borderColor = '#ff0000';
          document.getElementById('lastname').style.borderStyle = 'solid';
          document.getElementById('lastname').style.borderWidth = '2px';
      }
      else{
          document.getElementById('lastname').style.borderColor = '#ffffff';
          lastNameSelected = true;
      }

      let mailSelected = false;
      if (currentForm.email.value == ""){
          document.getElementById('email').style.borderColor = '#ff0000';
          document.getElementById('email').style.borderStyle = 'solid';
          document.getElementById('email').style.borderWidth = '2px';
      }
      else{
          document.getElementById('email').style.borderColor = '#ffffff';
          mailSelected = true;
      }
  
      if (currentForm.minutes.value == ""){
          currentForm.minutes.value = 0;
          if(currentForm.minutes.value == 0){
              console.log(1111);
          }
      }
      
      

      let secondsSelected = false;
      if (currentForm.seconds.value == ""){
          document.getElementById('seconds').style.borderColor = '#ff0000';
          document.getElementById('seconds').style.borderStyle = 'solid';
          document.getElementById('seconds').style.borderWidth = '2px';
      }
      else{
          document.getElementById('seconds').style.borderColor = '#ffffff';
          secondsSelected = true;
      }

      let formcorrect = false;
      if(firstNameSelected && lastNameSelected && mailSelected && secondsSelected){
          formcorrect = true;
      }

      if(formcorrect)
          return true;
      else{
          alert("Bitte füllen sie alle Felder aus."); //Kann auch Weggelassen bzw anders gemacht werden, wusste nicht wie es passt.
          return false;
      }
    },




  },

}

</script>

<style lang="scss" >

  @font-face {
    font-family: helveticaLR;
    src: url('/fonts/helveticaLR/HelveticaNeueLTPro-Lt.otf')
    format("opentype");
  }
  @font-face {
    font-family: helveticaLR2;
    font-weight: bold;
    src: url('/fonts/helveticaLR/HelveticaNeueLTPro-Bd.otf')
    format("opentype");
  }
  @font-face {
    font-family: helveticaLR2;
    font-weight: medium;
    src: url('/fonts/helveticaLR/HelveticaNeueLTPro-Md.otf')
    format("opentype");
  }

  .LRBG {
      // background-color: rgb(0, 118, 186);
      background-color: rgb(0, 149, 52);
      height: 100vh;
      width: 100vw;

      position: absolute;
      font-family: "helveticaLR", Verdana, Tahoma;
  }

  .logoLR {
      right: 9vw;
      top: 7vh;
      width: 36vw;
      height: 10vh;
    background-image: url("/logos/LogoLR.png");
    background-repeat: no-repeat;
    background-size: contain;
        position: absolute;
        z-index: 10;
  }
  
  #dataForm {
    input {
      width: 50vw !important;
    }
  }

  .timeInput {
    div {
          display: flex;
          input {margin: 0 1vw;}
    }
    
  }

    button {
      color: black !important;
      font-size: 2vh !important;
      padding: 0.7em 5vw !important;
      min-width: 35vw;
    }

  .startButton {
    position: absolute;
    bottom: 15vh;
    left: 7vw;
    margin: 2vw;
    background-color: white;
  }

.form1 {
  margin: 20vh 10vw 10vh;
}
.form2 {
  margin: 0vh 10vw;
}
  .formContainer {
    width: 80vw;
    font-size: 1.5vh;
    
    label {
      width: 25vw !important;
      color: white;
      text-align: left;
      font-size: 1.8vh;
      
    }
    input {
      height: 4vh;
      padding-left: 2vw;
    }
   
    .el-input {
          display: block;
          font-size: 1.7vh;
    }

    .el-form-item {
      position: relative;
      padding: .6vw 0;
    }

    button {
      color: black;
      font-size: 1.4vh;
      padding: 0.7em 5vw;
    }

    .vSpace {
      height: 5vh;
    }

    .buttoncontainer {
      margin: 6vh auto;
      display: flex;
      justify-content: space-around;
    }

    #clickFieldRow {
       display: flex;
       width: 80vw;
       padding: 4vw 0 0;

      .zustimmung {
        right: -11vw;

        .el-checkbox__input {
          zoom: 2.5;    
          display: flex;
        } 
      }
      
      .clicktext {
        color: white;
        font-size: 1.4vh;
        width: 80%;
      }
    }

    .zeitInput {
      width: 10vw;
      margin: 0 2vw;
    }
  }

.pdfContainer {
  position: fixed;
    left: 5vw;
    top: 5vh;
    width: 90vw;
    height: 90vh;
    z-index: 10;


  .pdfIFrame {
    position: absolute;
    width: 100%;
    height: 90%;
  }

  .OKButton {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

</style>

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import axios from 'axios';

import deviceStore from './mobile_storeX.js'
import luckywheel from "./luckywheelX.js"
import highscoreStore from "./highscoreX.js"

export default new Vuex.Store({
  state: {
    FEversion: {name: "FE", VERSION: "FE : V.0.21.0", datum:"23.8.2022" },

    baseApiUrl: 'http://tentable.de:8100/',
    
    lowCutValue: 18,

    arduinoData: {
      "sensor": "template",
      'timestamp': new Date().getTime(),
      "id": -1,
      "version": 0.3,
      "analogIn": [
        1021,
        1022,
        835,
        665,
        539,
        496
      ]
    },
    arduinoIsIntervalReading: false,

    axiosHeader: { headers: {} },

    dataArray: [0],
    energyList: [0],
    power: 0,
    powerNorm: 0,
    allPower: 0,

    A1ToWattFaktor: 0.00167,
    
    eSum: 0.0,
    energyHarvesting: false,

    powerMax: 400,
    counter: 0,
    
    sensorSettings: {
    },

      analogChannel1: {
        active: false,
        inputDeviceId: 1,
       // inputDeviceIP: '10.116.1.155', // Daniel Kiste - DrehKnöppe - zum Testen 
      //  inputDeviceSlotNr: 3,
      //  inputDeviceSlotNr2: 2 ,

             inputDeviceIP: '192.168.1.160', //  '192.168.1.160 - WINDPARK Allianz verkauf 
       inputDeviceSlotNr: 0,
       inputDeviceSlotNr2: 1 ,

        invertData: false,
        normData: false,
        rawData: -1,
        maxRawData: 1023,
        readInterval: 0,
        readIntervalTime: 300,
        lowCut: 20,
      },

      channel2: {
        active: false,
        inputDeviceId: 1,
        inputDeviceSlotNr: 4,
        invertData: false,
        normData: false,
        rawData: -1,
        maxRawData: 1023,
        analogReadInterval: 0,
      }

  }, // END _ STATE ::::: 
// ================================================================================

  mutations: {

    // .................... local Storage .....................
    clearLocalStorage () {
      window.localStorage.clear()
      console.log("X -> Mutation -> clearLocalStorage ")
    },

    loadSettingsFromLocalStorage(state) {
      const tempvar =  window.localStorage.getItem('analogChannel1')
      console.log("X -> loadSettingsFromLocalStorage : -> ", tempvar)
      if (tempvar) {
        state.analogChannel1 = JSON.parse(tempvar)
      } else {
        //get the connections from your API
        //once you have the data, display it and also save it  
        console.log("X -> loadPageHistoryFromLocalStorage : -> init item: with ", state.analogChannel1)
          // localStorage.setItem('userSet', state.userSet)
          window.localStorage.removeItem('analogChannel1')
          window.localStorage.setItem('analogChannel1', JSON.stringify(state.analogChannel1))
      }

      const _ip =  window.localStorage.getItem('serverApiUrl')
      if (_ip) {
        state.baseApiUrl = _ip
      } else {
          window.localStorage.removeItem('serverApiUrl')
          window.localStorage.setItem('serverApiUrl', state.baseApiUrl)
      }

    },

    saveSettingsToLocalStorage(state) {
      console.log('X -> saveSettingsToLocalStorage ->  ', state.analogChannel1)
      window.localStorage.setItem('analogChannel1', JSON.stringify(state.analogChannel1))
      window.localStorage.setItem('serverApiUrl', state.baseApiUrl)
    },
    // ............................... END local.storage.............

    /** TODO --- funzt so nur für einen channel / player */
    setArduinoIp (state, _ip) {
      state.analogChannel1.inputDeviceIP = _ip
    },

    setArduinoData (state, _data) {
      const dataArrayLength = 50
      // _data.timestamp = new Date().getTime()
      // console.log(' X -> setArduinoData: ' , _data)
      state.dataArray.push(_data)
      if (state.dataArray.length > dataArrayLength) {
        state.dataArray.splice(0,1)
      }
      state.arduinoData = _data
      state.counter = state.counter + 1
    },

    setCannel1RawData(state, _data) {
      const myChan = state.analogChannel1
      let myData = _data[myChan.inputDeviceSlotNr]
      if (myChan.invertData === true) {
        myData = 1023 - myData
      }

      if (myChan.normData === true) {
        myData = myData / myChan.maxRawData
      }
      myChan.rawData = myData
    },

    setChannel1Props(state, o) {
      Object.assign(state.analogChannel1, o)
    },

    setA1ToWattFaktor( state, _data) {
      state.A1ToWattFaktor = _data
    },

    addEnergy (state, {power, timestamp}) {
      const deltaT = (timestamp - state.arduinoData.timestamp)  //  / 1000.0
      state.eSum = state.eSum + (power *  deltaT)
 //     console.log(' X -> addEnergy : ' + state.eSum , power)
    },
    

    setEnergyNorm (state, _e) {
      state.powerNorm = _e
    },

    setTotalEnergy (state, _e) {
      state.eSum = _e
    },


    
    setEnergy (state, _e) {
      // console.log(' X -> setEnergy : ', _e )
      state.power = _e
      state.energyList.push(_e )
      
      const timearrayLength = 50
      if (state.energyList.length > timearrayLength) {
        state.energyList.splice(0,1)
      }
    },

    setEnergyHarvesting (state, _e) {
      console.log(' X -> setEnergyHarvesting : ' + _e)
      state.energyHarvesting = _e
    },
    

    setIsIntervalReading (state, value) {
      state.arduinoIsIntervalReading = value
    },

    setApiUrl (state, value) {
      state.baseApiUrl = value
    },
    setSensorOffset (state, value) {
      state.lowCutValue = value
    }

    

  }, // END mutations ............. 
  
  // ===========================================================
  // ------------------------ start Actions ------------------------------------
  actions: {

    initSensors ({commit, state}) {
      //let newChannel = JSON.parse(JSON.stringify(state.analogChannelTemplate))
      // newChannel
      // state.settings.analogChannel1 = 
      console.log("===================== initSensors =================")
       commit('loadSettingsFromLocalStorage')
    },

    saveSettingsPersistent({commit}) {
      commit("saveSettingsToLocalStorage")
    },

    async getArduinoDirect({dispatch, state}) {

      let url = "http://" + state.analogChannel1.inputDeviceIP
      let    requestOptions = { headers: {} }

      let response = await axios.get(url, requestOptions)    
      .then(response => this.dispatch('parseArduinoData', response.data))
      .catch(err => {
        console.log(err);
      });
    },
    async getArduinoDirectIP({dispatch, state}) {

//       let url = "http://192.168.1.160"
      const url = "http://localhost:8090/api/wind"
      
      const    requestOptions = { headers: {} }

      let response = await axios.get(url, requestOptions)    
      .then(response => this.dispatch('parseArduinoData', response.data))
      .catch(err => {
        console.log(err);
      });
    },

    parseArduinoData ({commit, dispatch, state}, _data) {
		
		console.log("parseArduinoData -> ", _data)
		
      _data.timestamp = new Date().getTime()

       const mySensor = {
        name: "arduino uno",
        controllerType: "arduino",
        type: "windrad",
        pinNr: 1,
        maxRawData: 300
      }

      let power = 0
      let powerNorm = 0

      if (mySensor.type == "windrad") {
        // const lowCutValue = 18;


        power =  0
        for (let i = 0; i < 6; i++) {
          if (_data.analogIn[i] > state.lowCutValue) {
            power = power + _data.analogIn[i] - state.lowCutValue;
          }
        }
         // power =   _data.analogIn[0] + _data.analogIn[1]  + _data.analogIn[2]  + _data.analogIn[3]  + _data.analogIn[4]  + _data.analogIn[5] 
        // //if (power < windradLowCut) {
        //   power = 0
        // } 
        powerNorm = power / mySensor.maxRawData
      } else {
        power = _data.analogIn[state.analogChannel1.inputDeviceSlotNr]
        if (power < state.analogChannel1.lowCut) {
          power = 0
        }
        powerNorm = power / state.analogChannel1.maxRawData
      }

      // console.log(' X -> parseArduinoData: ', _data)
      if (state.energyHarvesting === true) {
        commit('addEnergy', { power: powerNorm, timestamp: _data.timestamp})
      }
      commit('setEnergy', power)
      commit('setEnergyNorm', powerNorm)

      commit('setArduinoData', _data)
      commit('setCannel1RawData', _data.analogIn)

      if (state.counter % 100 === 0 ) {
        dispatch('saveEnergyToLocalStorage', state.eSum)
      }
      state.counter = state.counter + 1
    },

    resetEnergy ({commit}) {
      console.log(" X -> resetEnergy ")
      commit('setTotalEnergy', 0)
    },
      
    saveEnergyToLocalStorage ({},_e) {
        localStorage.setItem("energy", _e);
    },

    resetLocalStorage({dispatch}, resetValue) {
      console.log("resetLocalStorage .> set Energy :  ", resetValue);
      dispatch('saveEnergyToLocalStorage', resetValue);
    },

    loadDataFromLocalStorage({dispatch, commit}) {
      let e  = Number(localStorage.getItem("energy")); 
      console.log('doc ready -> load from local Storag', e);
      if (isNaN(e)) {
        console.log('NaN');
        e = 0;
        dispatch('resetLocalStorage', 0);
      }
      commit('setEnergy', e)
    },

    startChannel1({state, dispatch}) {
      console.log("++++++++++++ X ->  startChannel1 ++++++++++++");
      state.analogChannel1.active = true
      state.analogChannel1.readInterval = setInterval(function() {dispatch('getArduinoDirect')}, state.analogChannel1.readIntervalTime)
    },

    stopChannel1 ({state}) {
      console.log("------------- X -> stopChannel1 -----------");
      state.analogChannel1.active = false
      clearInterval(state.analogChannel1.readInterval)
    },


    setArduinoIp({commit}, _ip) {
      console.log("X -> setArduinoIp() ", _ip);
      const o = {inputDeviceIP: _ip}
      commit('setChannel1Props', o)
    },
    setChannel1SlotNr({commit}, _nr){
      const o = {inputDeviceSlotNr: _nr}
      commit('setChannel1Props', o)
    },
    setIntervalTime({commit}, _val){
      const o = {readIntervalTime: _val}
      commit('setChannel1Props', o)
    },

    clearLocalStorage({commit}) {
      commit('clearLocalStorage')
    },

    setEnergyHarvesting ({commit}, _e) {
      console.log(' X -> setEnergyHarvesting (): ' + _e)
      commit('setEnergyHarvesting', _e)
    },

    setA1ToWattFaktor ({commit}, _e) {
      console.log(' X -> setA1ToWattFaktor (): ' + _e)
      commit('setA1ToWattFaktor', _e)
    },

    setApiUrl ({commit}, _e) {
      console.log(' X -> setApiUrl (): and save to localStorage .. ' + _e)
      commit('setApiUrl', _e)
      commit('saveSettingsToLocalStorage')
    },
    
    setSensorOffset ({commit}, _e) {
      console.log(' X -> setSensorOffset (): and' + _e)
      commit('setSensorOffset', _e)
    },

  }, 
  // ------------------------ END Actions ------------------------------------

  modules: {
    luckywheel: luckywheel,
    deviceStore: deviceStore,
    highscoreStore: highscoreStore,
  },
  
  getters: {
    arduinoData: state => { return state.arduinoData},
    arduinoIsIntervalReading: state => { return state.arduinoIsIntervalReading},
    axiosHeader: state => { return state.axiosHeader },
    A1ToWattFaktor: state => { return ( state.A1ToWattFaktor) },
    A1Power: state => { return ( state.energyList[state.energyList.length-1] ) },
    baseApiUrl: state => { return ( state.baseApiUrl ) },
    power: state => { return ( state.power ) },
    powerNorm: state => { return ( state.powerNorm ) },
    eSum: state => { return ( Math.floor(state.eSum ) ) },
    analogArduinoIp: state => { return state.analogChannel1.inputDeviceIP },
    channel1: state => { return state.analogChannel1 },
    isEnergyHarvesting : state => { return state.energyHarvesting },
  }

})

<template>
  <div class="pageContainer text" >
        <!-- <img alt="Vue logo" src="../assets/icon_power.png"> -->
       <h1>  DEINE LEISTUNG </h1>
    <div class="powerAsNumber">
      <h2>{{ Math.floor(channel1.rawData) }} </h2>
      Watt
    </div>

  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import { mapActions } from "vuex";

export default {
  name: 'InstantPower',

computed: {
    ...mapGetters([
      // 'arduinoAnalogIn',
      'A1Power',
      'A1ToWattFaktor',
      'arduinoData',
      'channel1',

    ]),
  },

  // mounted() {
  //   this.loopGetArduinoData()
    
  // },

   methods: {
    
    // ...mapActions([
    //   'getArduinoDirect',
    // ]),
   },

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.info {
  position: absolute;
  width: 80vw;
  top: 70vh;
  left: 10vw;
}
</style>

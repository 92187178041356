<template>
<div  class="ttcontainer">
  <MainNav /> 
    
  <div id="about" class="text">

    <title>Brand Unit Berlin meets tentable</title>
     <h1> About BUB - All in One </h1>
    <subtitle>Brand Unit Berlin meets tentable</subtitle>
  
    this Page is based on the normal open Internet. 

    To communicate with our devices we have to be in the same local network like the devices we will call. 



    <h2> Überblick  </h2>
      in section Settings erhalten wir einen Überblick über vorhanden und möglichen devices. 
      (online Status wird allerdings noch nicht angezeigt - aber bei click auf das bild ...) ) 
    <div class="helpContainer">>
      <h2>TroubleShooting</h2>
        <h3> CORS </h3>
        evtl muss man neuerdings folgendes in Google Chrome einstellen : 
      <ul>
        <li>chrome://flags/#block-insecure-private-network-requests</li>
      </ul>
    </div>
    <h1>Infos</h1>
    <div class="helpContainer">
      <h2>Start with QR Code</h2>
      Interaction mit Schaufensterbesuche 
      <ul>
        <li> QR code innen auf Display <br> 
            http://localhost:8080/startQR
            </li>
        <li> Besucher scannt QR code</li>
        <li> Besucher Device zeigt Eingabemaske für Namen und Email <br>
            http://tentable.de/bub/registerFromQR/gluecksrad
             </li>
        <li> Nach Eingabe von Namen (und click auf Button) beginnt die Lotterie auf dem indoor Screen <br>
              /remotePlayerCall/tester
              </li>
        <li> wenn Email vorher eingegeben wurde, erhalt einer EMail mit Gewinn Coupon oder so </li>
      </ul>
    </div>
    <div class="helpContainer">
      <h2>KickCounter</h2>
      Incrementeller Zähler. 
      <ul>
        <li> reagiert auf Tastendruck</li>
        <li> Speichert den aktuellen Wert nichrflüchtig im Browser</li>
        <li> Aktueller Wert einstellbar über die Ziffern 1 - 5 in 10^x Schritten</li>
      </ul>
    </div>
    <div class="helpContainer">
      <h2>SimpleStopWatch</h2>
      CountDown
      <ul>
        <li> space -> start</li>
        <li> space -> stop </li>
        <li> space -> reset</li>
      </ul>
    </div>
    <div class="helpContainer">
      <h2>Glücksrad TCom</h2>
      <ul>
        <li> Portrait Modus</li>
      </ul>
    </div>
    <div class="helpContainer">
       <h2>Glücksrad AOK</h2>
      <ul>
        <li> Portrait Modus</li>
      </ul>
    </div>
  </div>
</div>
</template>


<script>
  // import {mapGetters} from "vuex";
  // import { mapActions } from "vuex";
  import MainNav from '@/components/Navi.vue'; // @ is an alias to /src

export default {
  name: 'About',

  components: {
    MainNav,
  },

  data() {
    return {
      sections: [
        {
          title: 'Start with QR Code',
          section: 'Auf dem Screen sieht man nur einen QR Code ',
        }
      ]
    }
  },

  // computed:{
  //   ...mapGetters([
  //   ]),
  // },
  // mounted() {
  // },
}

</script>

<style scoped>

  div {
    color: blueviolet;
  }

  h1, h2, h3 {
    color: white;
    margin-block-end: 0.3em;
  }
  #about {
    padding: 0 25vw;
    text-align: left;
    color: #cccccc;
    overflow: scroll;
    overflow-x: hidden;
    font-size: 1.3rem;
  }

  .helpContainer {
    font-size: 1.3rem;
    text-align: left;
    color: #dddddd;
    font-family: Frutiger, Hevetica, Arial, sans-serif;
    /* max-width: 60vw; */
    /* width: 50vw;
    position: relative; */
  }

  .ttcontainer {
    background-color: #010406;
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

</style>

  import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import About from '../views/About.vue'
import Home from '../views/Home.vue'
import NetBike from '../views/NetBike.vue'
import HighScore from '../views/HighScore.vue'
// import LuckyW from '@/views/Glueksrad.vue'
import LoginComponent from "../views/Login.vue"

Vue.use(VueRouter)

// 1673452656032

const routes: Array<RouteConfig> = [
  {
        path: "/login",
        name: "login",
        component: LoginComponent
    },
  {
    path: '/qrimg/:code?',
    name: 'QrImg',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/QrImg.vue')
  },
  {
    path: '/allianz/ranglistetotal',
    name: 'RanglisteAllianz',
    component: () => import('../views/windpark/AllianzRanglisteTotal.vue')
  },
  {
    path: '/allianz/start',
    name: 'AllianzStart',
    component: () => import(/* webpackChunkName: "about" */ '../views/windpark/AllianzStart.vue')
  },
  {
    path: '/allianz/rangliste',
    name: 'RanglisteAllianz',
    component: () => import(/* webpackChunkName: "about" */ '../views/windpark/AllianzRangliste.vue')
  },
  {
    path: '/allianz/register',
    name: 'AllianzTablet',
    component: () => import(/* webpackChunkName: "about" */ '../views/windpark/AllianzTablet.vue')
  },
  {
    path: '/windpark',
    name: 'AllianzWindPark',
    component: () => import(/* webpackChunkName: "about" */ '../views/windpark/AllianzWindPark.vue')
  },
  {
    path: '/quizrad',
    name: 'QuizRadCDU',
    component: () => import(/* webpackChunkName: "about" */ '../views/QuizRadCDU.vue')
  },
  {   
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/netbike/:opt',
    name: 'NetBike',
    component: NetBike,
    props: true
  },
  {
    path: '/events',
    name: 'Events',
    component: () => import(/* webpackChunkName: "about" */ '../views/Events.vue')
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import('../views/Test.vue')
  },
  {
    path: '/luckyedit',
    name: 'LuckyEdit',
    component: () => import(/* webpackChunkName: "about" */ '../views/LuckyEdit.vue')
  },
  {
    path: '/luckyw',
    name: 'LuckyW',
    component: () => import(/* webpackChunkName: "about" */ '../views/GluecksRadMaster.vue'),
    alias: '/bub/luckyw' 
  },
  {
    path: '/luckywBUB',
    name: 'LuckyBUB',
    component: () => import(/* webpackChunkName: "about" */ '../views/GluecksRadBUB.vue')
  },
  {
    path: '/luckywTC',
    name: 'LuckyWTC',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/GluecksRadTCom.vue')
  },
  {
    path: '/luckywAOK',
    name: 'LuckyAOK',
    component: () => import(/* webpackChunkName: "about" */ '../views/GluecksRadAOK.vue')
  },
  {
    path: '/kickcounter',
    name: 'KickCounter',
    component: () => import(/* webpackChunkName: "about" */ '../views/KickCounter.vue')
  },
  {
    path: '/eurocounter',
    name: 'EuroCounter',
    component: () => import(/* webpackChunkName: "about" */ '../views/EuroCounter.vue')
  },
  {
    path: '/timebuzzer/:timeToGo?',
    name: 'TimeBuzzer',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/TimeBuzzer.vue')
  },
  {
    path: '/counterStopWatch',
    name: 'CounterStopWatch',
    component: () => import(/* webpackChunkName: "about" */ '../views/CounterStopWatch.vue')
  },
  {
    path: '/simpleStopWatch',
    name: 'SimpleStopWatch',
    component: () => import(/* webpackChunkName: "about" */ '../views/SimpleStopWatch.vue')
  },
  {
    path: '/smoothieBike',
    name: 'SmoothieBike',
    component: () => import(/* webpackChunkName: "about" */ '../views/SmoothieBike.vue')
  },
  {
    path: '/powerbike',
    name: 'PowerBike',
    component: () => import(/* webpackChunkName: "about" */ '../views/PowerBike.vue')
  },
  {
    path: '/streetbike',
    name: 'StreetBike',
    component: () => import(/* webpackChunkName: "about" */ '../views/StreetBike.vue')
  },
  {
    path: '/doublebike',
    name: 'DoubleBike',
    component: () => import(/* webpackChunkName: "about" */ '../views/finals/DoubleBikeAlexa.vue')
//    component: () => import(/* webpackChunkName: "about" */ '../views/finals/JohannsDoubleBike.vue')
  },
  {
    path: '/alexabike',
    name: 'AlexaBike',
    component: () => import(/* webpackChunkName: "about" */ '../views/finals/DoubleBikeAlexa.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "about" */ '../views/Settings.vue')
  },
  {
    path: '/mobile/register/:gamename',
    name: 'registerFromQR',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/mobile/RegisterViaQR.vue')
  },
  {
    path: '/mobile/buttoncall/:id',
    name: 'mobileStartLocal',
    props: true,
    component: () => import('../views/mobile/ButtonCall.vue')
  },
  {
    path: '/highscore/:eventName',
    name: 'HighScore',
    props: true,
    component: HighScore
  },
  {
    path: '/highscore/setup',
    name: 'HighScoreSetUp',
    props: true,
    component: () => import('../views/highscore/SetUp.vue')
  },
  {
    path: '/insert4hs',
    name: 'Insert4hs',
    props: true,
    component: () => import('../views/Insert4Hs.vue')
  },
  {
    path: '/live/:projectName',
    name: 'WheelFortune',
    props: true,
    component: () => import('../views/WheelFortune.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

import axios from 'axios';

const state = {
  axiosHeader: { headers: {} },

  wheeldata: {},
  luckyApiUrl: 'luckyapi/',
  mobileApiUrl: 'mobileapi/',
  highscoreApiUrl: 'highscoreapi/',
  startLocalFromMobileUrl: 'mobileapi/startFromQR/',
  baseUrlFileUpload: 'fileUpload/',

  getNextPlayerUrl: 'mobileapi/countWarteliste/', // + myId 
  
 // productiveUrl: 'http://localhost:8090/live/',
  // productiveUrl: 'http://weissstreicher.de/live/',
  productiveUrl: 'http://wheel-fortune.de/live/',



  currentView: '',
  datum: 'nix',
  projectFileNames: [],
  versionDataStructure: '0.1.5',
  VERSIONS: [
  //{ "VERSION": "unknown",
//  "name": "try to connect server" }
    ],
  };
// ============================ END STATE ===========================

// =========================== GETTERS :::::::::::::::::::::::::
const getters = {
  apiUrl: (state, rootState) => { return rootState.baseApiUrl + state.luckyApiUrl },
  baseUrl: rootState => { return  rootState.baseApiUrl },
  baseUrlFileUpload: (state, rootState) => { return rootState.baseApiUrl + state.baseUrlFileUpload },
  currentView: state => { return state.currentView },
  datum: state => { return state.datum },
  productiveUrl: state => { return state.productiveUrl},
  projectFileNames: state => { return state.projectFileNames},
  wheeldata: state => { return state.wheeldata },
  versionDataStructure: state => { return state.versionDataStructure },
  VERSIONS: state => {return state.VERSIONS},
};
// ===========================  END - GETTERS :::::::::::::::::::::::::

// ================== ACTIONS =========================
const actions = {

    async getApiVersions ({commit, state, rootState}) {
      commit("addVersion", rootState.FEversion) //response.data?
       console.log(" X () -> getApiVersions: ")
      let response = await axios.get(rootState.baseApiUrl + 'api/info', state.axiosHeader)
      .then(response =>  commit("addVersion", response.data)) //response.data?
      .catch(err => { console.log(' X -> getApiVersions -> Error ', err);
      });
      response = await axios.get(rootState.baseApiUrl + state.luckyApiUrl + 'info', state.axiosHeader)
      .then(response =>   commit("addVersion", response.data))
      .catch(err => { console.log(' X -> getApiVersions -> Error ', err);
      });
      response = await axios.get(rootState.baseApiUrl + state.mobileApiUrl + 'info', state.axiosHeader)
      .then(response =>   commit("addVersion", response.data))
      .catch(err => { console.log(' X -> getApiVersions -> Error ', err);
      });
      response = await axios.get(rootState.baseApiUrl + state.highscoreApiUrl + 'info', state.axiosHeader)
      .then(response =>   commit("addVersion", response.data))
      .catch(err => { console.log(' X -> getApiVersions -> Error ', err);
      });
    },

    async uploadFile ({state, rootState}, file) {
      console.log(" Y -> uploadFile: ", file)
      try {
        let formData = new FormData()
        formData.append('nix', "michnix");
        formData.append('file', file)
        formData.append('fileType', 'image')
        const result = await fetch(rootState.baseApiUrl + state.baseUrlFileUpload, {
          method: 'POST',
          body: formData
        })
        console.log (" X -> uploadFile : ", result)
        return result.json()
      } catch (err) {
        error('Unable to upload file')
      }
    },

    async loadLuckyByName({dispatch}, _name) {
      let url = '/data/' + _name + '.json'
      fetch(url)
      .then(response => response.json())
      .then(data => dispatch("setWheelData", data));
    }, 

    async loadLuckyFromLocalStorage({dispatch}) {
      let tempvar =  window.localStorage.getItem('lucky')
      console.log("X -> loadLuckyFromLocalStorage : -> ", tempvar)
      if (tempvar) {
        dispatch('setWheelData', JSON.parse(tempvar))
      } else {
        //get the connections from your API
        //once you have the data, display it and also save it
        console.log("loadLuckyFromLocalStorage : -> init item: with ")
          // localStorage.setItem('userSet', state.userSet)
          window.localStorage.removeItem('lucky')
          window.localStorage.setItem('lucky', "{}")
      }
    },

    async loadLuckyLocal({dispatch, state}, _name) {
      console.log(' X - loadLuckyLocal : ' + _name )
      let url = '/data/' + _name + '.json'
      let response = await axios.get(url, state.axiosHeader)
      dispatch('setWheelData', response.data)
      console.log('X -> loadLuckyLocal -> name: ' + _name + " -  res: ", response)
    },
    async loadLuckyFromServer({dispatch, state, rootState}, _name) {
      console.log(' X - loadLuckyFromServer : ' + _name )
      let url = rootState.baseApiUrl + state.luckyApiUrl + 'getLucky/' + _name
      let response = await axios.get(url, state.axiosHeader)
      console.log("loadLuckyFromServer -->",response.data)
      dispatch('setWheelData', response.data)
      console.log('X -> loadLuckyFromServer -> name: ' + _name + " -  res: ", response)
    },

    async loadProjectNamesFromServer({dispatch, state, rootState}) {
      let url = rootState.baseApiUrl + state.luckyApiUrl + 'getconfigfilenames'
      let response = await axios.get(url, state.axiosHeader)
      dispatch('setProjectFileNames', response.data.fileNames)
    },

    saveLuckyToLocalStorage({},_luckyData) {
      console.log("!!!!!: ", JSON.stringify((_luckyData)))
      console.log('saveSettingsToLocalStorage ->  ', _luckyData)
       window.localStorage.setItem('lucky', JSON.stringify(_luckyData))
    },

    saveLuckyToServer({dispatch, rootState},_luckyData) {
      
      dispatch('setDatum')
      console.log('X -> saveLuckyToServer ->  ', state.datum)
      console.log('X -> saveLuckyToServer ->  ', _luckyData)
      const url = rootState.baseApiUrl + state.luckyApiUrl + 'saveConfig'
      let response = axios.post(url, _luckyData, state.axiosHeader)
      .then(response =>  console.log("X -> Uploaded .... TODO - User Notification ", response))
      .catch(err => {
        console.log(err);
      });
      console.log(response)
    },

    setProjectFileNames ({commit}, _data) {
      console.log('LuckyW_storeX -> setProjectFileNames _> action ')
       commit('setProjectFileNames', _data)
    },
   
    setWheelData ({commit}, _data) {
      console.log('LuckyW_storeX -> setWheelData _> action - make new random gift ')
      if (_data.useQR === true) {
        commit('setCurrentView', "QR")
      } else {
        commit('setCurrentView', "wheel")
      }
      commit('setWheelData', _data)
    },

    startLocalFromMobile ({commit, state, rootState}, _data) {
      let url = rootState.baseApiUrl + state.startLocalFromMobileUrl + _data
      let response = axios.get(url, this.axiosHeader)
      .then(response =>  console.log('sendData -> recieved: ' + response)) //response.data?
      .catch(err => { console.log(' X -> startLocalFromMobile -> Error ', err);
      });
    },
    setPlayerName ({rootState}, _data) {
      let url = rootState.baseApiUrl + "mobileapi/setplayername/" + _data
      let response = axios.get(url, this.axiosHeader)
      .then(response =>  console.log('sendData -> recieved: ' + response)) //response.data?
      .catch(err => { console.log(' X -> startLocalFromMobile -> Error ', err);
      });
    },
    
    setCurrentView ({commit}, _data) {
      commit("setCurrentView", _data)
    },

    startLocal({commit}, _data) {
      commit("setCurrentView", 'wheel')
    },

    setversionDataStructure ({commit}, _data) {
      commit("setversionDataStructure", _data)
    },

    setDatum ({commit}) {
      this.datum = new Date();
      this.datum = this.datum.toLocaleString();
      commit("setDatum", this.datum)
    },

};
// ================== END ACTION  =========================

// ================== MUTATIONS =========================
const mutations = {

  addVersion(state, _data) {
    console.log('LuckyW_storeX -> mutation -> addVersion', _data)
    state.VERSIONS.push(_data)
  },

  /** set the wheel data
   * and make a new random gift ...
   */
  setWheelData(state, _data) {
    console.log('LuckyW_storeX -> mutation -> setWheelData', _data)
    state.wheeldata = _data
  },
  /* setBaseUrl(state, _data) {
    console.log('LuckyW_storeX -> mutation -> setBaseUrl', _data)
    state.baseUrl = _data
  },*/

  setCurrentView(state, _data) {
    console.log('LuckyW_storeX -> mutation -> setCurrentView', _data)
    state.currentView = _data
  },

  setProjectFileNames(state, _data) {
    console.log('LuckyW_storeX -> mutation -> setProjectFileNames', _data)
    state.projectFileNames = _data
  },

  setversionDataStructure(state, _data) {
    console.log('LuckyW_storeX -> mutation -> setversionDataStructure', _data)
    state.setversionDataStructure = _data
  },
  setDatum(state, _data) {
    console.log('LuckyW_storeX -> mutation -> setDatum', _data)
    state.datum = _data
  },

};

export default {
  state,
  getters,
  actions,
  mutations
};
 
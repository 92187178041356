<template>
  <div class="container text">
        <!-- <img alt="Vue logo" src="../assets/icon_energy.png"> -->
      
      <h1>  GESAMT </h1>
    <div class="EnergyAsNumber">
      <h2>{{ eSum }} </h2>
      Wattsekunden
    </div>

  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  // import { mapActions } from "vuex";

export default {
  name: 'EnergySimple',

  computed:{
    ...mapGetters([
      'eSum',
    ]),
  },

  mounted() {
  },


}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}


</style>

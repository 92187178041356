var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ttcontainer"},[_c('MainNav'),_c('div',{staticClass:"text",attrs:{"id":"about"}},[_c('title',[_vm._v("Brand Unit Berlin meets tentable")]),_c('h1',[_vm._v(" About BUB - All in One ")]),_c('subtitle',[_vm._v("Brand Unit Berlin meets tentable")]),_vm._v(" this Page is based on the normal open Internet. To communicate with our devices we have to be in the same local network like the devices we will call. "),_c('h2',[_vm._v(" Überblick ")]),_vm._v(" in section Settings erhalten wir einen Überblick über vorhanden und möglichen devices. (online Status wird allerdings noch nicht angezeigt - aber bei click auf das bild ...) ) "),_vm._m(0),_c('h1',[_vm._v("Infos")]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"helpContainer"},[_vm._v("> "),_c('h2',[_vm._v("TroubleShooting")]),_c('h3',[_vm._v(" CORS ")]),_vm._v(" evtl muss man neuerdings folgendes in Google Chrome einstellen : "),_c('ul',[_c('li',[_vm._v("chrome://flags/#block-insecure-private-network-requests")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"helpContainer"},[_c('h2',[_vm._v("Start with QR Code")]),_vm._v(" Interaction mit Schaufensterbesuche "),_c('ul',[_c('li',[_vm._v(" QR code innen auf Display "),_c('br'),_vm._v(" http://localhost:8080/startQR ")]),_c('li',[_vm._v(" Besucher scannt QR code")]),_c('li',[_vm._v(" Besucher Device zeigt Eingabemaske für Namen und Email "),_c('br'),_vm._v(" http://tentable.de/bub/registerFromQR/gluecksrad ")]),_c('li',[_vm._v(" Nach Eingabe von Namen (und click auf Button) beginnt die Lotterie auf dem indoor Screen "),_c('br'),_vm._v(" /remotePlayerCall/tester ")]),_c('li',[_vm._v(" wenn Email vorher eingegeben wurde, erhalt einer EMail mit Gewinn Coupon oder so ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"helpContainer"},[_c('h2',[_vm._v("KickCounter")]),_vm._v(" Incrementeller Zähler. "),_c('ul',[_c('li',[_vm._v(" reagiert auf Tastendruck")]),_c('li',[_vm._v(" Speichert den aktuellen Wert nichrflüchtig im Browser")]),_c('li',[_vm._v(" Aktueller Wert einstellbar über die Ziffern 1 - 5 in 10^x Schritten")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"helpContainer"},[_c('h2',[_vm._v("SimpleStopWatch")]),_vm._v(" CountDown "),_c('ul',[_c('li',[_vm._v(" space -> start")]),_c('li',[_vm._v(" space -> stop ")]),_c('li',[_vm._v(" space -> reset")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"helpContainer"},[_c('h2',[_vm._v("Glücksrad TCom")]),_c('ul',[_c('li',[_vm._v(" Portrait Modus")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"helpContainer"},[_c('h2',[_vm._v("Glücksrad AOK")]),_c('ul',[_c('li',[_vm._v(" Portrait Modus")])])])
}]

export { render, staticRenderFns }
<template>
  <div>
    <ConSilio
           v-if="eventName === 'consilio'">
    </ConSilio>

    <LohmannRauscher
      v-if="eventName === 'LR'">
    </LohmannRauscher>
  </div>
</template>



<script>
  import {mapGetters} from "vuex";
  // import { mapActions } from "vuex";
 // import MainNav from '@/components/Navi.vue'; // @ is an alias to /src
  import LohmannRauscher from '@/views/finals/LohmannRauscher.vue'; // @ is an alias to /src
  import ConSilio from '@/views/finals/ConSilio.vue'; // @ is an alias to /src

export default {
  name: 'HighScore',
  props: //["eventName" || "LR"],
   {   eventName: {
      default: 'consilio',
      type: String,
   }  },

  components: {
    LohmannRauscher,
    ConSilio
  },


   data () {
    return {

    }
  },
  computed:{
    ...mapGetters([
    ]),
  },

  mounted() {
  },

}

</script>

<style scoped>
  


</style>

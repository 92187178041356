<template>
    <div class="navi" id="nav">

      <Info></Info>

      <!-- <router-link to="/">Home</router-link> | -->
      <router-link to="/netbike/snormal">Tacho</router-link> |
      <router-link to="/netbike/settings">Tacho mit Settings</router-link> |
      <router-link to="/streetbike">StreetBike</router-link> |
      <router-link to="/powerbike">Power</router-link> |

      <br>
      <router-link to="/luckyw">Glücksrad</router-link> |
      <router-link to="/luckywBUB"> BUB </router-link> |
      <router-link to="/luckywTC"> TCom </router-link> |
      <router-link to="/luckywAOK"> AOK </router-link> |
      <router-link to="/luckyedit"> edit </router-link> |
     
      <br>
      <router-link to="/KickCounter">KickCounter</router-link> |
      <router-link to="/EuroCounter">EuroCounter</router-link> |
      <router-link to="/counterStopWatch">CounterStopWatch</router-link> |
      <router-link to="/simpleStopWatch">SimpleStopWatch</router-link> |
      <router-link to="/smoothieBike">SmoothieBike</router-link> |
      <br>

      <router-link to="/events">Events</router-link> |
      <!-- <router-link to="/highscore">HighScore</router-link> | -->
      <router-link to="/highscore/LR">LR</router-link> |
      <router-link to="/highscore/consilio">consilio</router-link> |
      <router-link to="/doublebike">DoubleBike</router-link> |
      <router-link to="/alexabike">AlexaBike</router-link> |
      <br>
      <router-link to="/settings">Settings</router-link> |
      <router-link to="/about">About</router-link> |
      <br>
      <router-link to="/startqr"> Start QR </router-link> | 
      <router-link to="/mobile/register/tester">register</router-link> |
      <router-link to="https://mobile/mobilespin">mobilespin</router-link> |
      
      <!-- <router-link to="/arduinos">Arduinos</router-link> |
      <router-link to="/windrad">WindRad</router-link> |
      <router-link to="/barbike">BarBike</router-link> |
      <router-link to="/spacebike">SpaceBike</router-link> |
      <router-link to="/streetbike">StreetBike</router-link> | -->


    </div>
</template>

<script>
  import {mapGetters} from "vuex";
  // import { mapActions } from "vuex";

  import Info from "@/components/devcomps/Infos.vue"

export default {
  name: 'Navi',

  computed:{
    ...mapGetters([
      'VERSION',
    ]),
  },

  components: {
    Info
  },

  mounted() {
  },

}

</script>

<style scoped>

  .navi {
    background-color: aliceblue;
  }
  
  #nav {
    padding: 15px;
    align-content: center;
    text-align: center;
  }

  #nav a {
    font-weight: bold;
    color: #2c3e50;
  }

  #nav a.router-link-exact-active {
    color: #42b983;
  }

  .versionContainer {
    position: absolute;
    left: 0;
    top: 0;
    margin: 0.5rem; 
    font-weight: bold;
    font-size: 1rem;
  }

</style>

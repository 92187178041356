<template>
  <div class="tachoview">
    <div class="contentContainer" >
      <InstantPower />
      <EnergySimple />
    </div>
    
    <TachoRund id="tacho" />

<!--  -->
    <div 
        id="NetBikeSettings"
        class="settingsContainer"
        v-if="opt === 'settings'"
        >
        Settings ... {{opt}}
        <br>

        <div class="info">
          {{arduinoData}}
        </div>

        <div 
            class="sensorDubugContainer"
          >
          <div class="sensorDebugDiv"
            v-for="(a, index) in arduinoData.analogIn" :key="index"
              :style="classStyle(index)">
             {{ a }}
          </div>

        </div>
    </div>

  </div>
</template>

<script >
import { Component, Vue } from 'vue-property-decorator';
import InstantPower from '@/components/InstantPower.vue'; // @ is an alias to /src
import EnergySimple from '@/components/EnergySimple.vue'; // @ is an alias to /src
import TachoRund from '@/components/TachoRund.vue'; // @ is an alias to /src

  import {mapGetters} from "vuex";
  import { mapActions } from "vuex";

export default {
  props: ['opt'],
  components: {
    InstantPower,
    EnergySimple,
    TachoRund,
  },
  data() { return {
      interval: null,
    }
  },

  created () {
    this.setMySensorPinNr(0)
    this.loadDataFromLocalStorage()
  },

  mounted () {
    this.interval = setInterval(this.getArduinoDirect, 200); 
  },


  computed:{
    ...mapGetters([
      'arduinoData',
      'myArduinoId'
    ]),
  },

  beforeDestroy: function(){
    clearInterval(this.interval);
  },

 methods: {

   classStyle(i) {
        let col = Math.floor(Number(this.arduinoData.analogIn[i])   / 4);
        let colString = "rgb("+ col + ", " + col + ', ' + col + ')'
        return { 'background-color' : colString};
    },
    
    ...mapActions([
      'loadDataFromLocalStorage',
      'getArduinoDirect',
      'setMySensorPinNr'
    ]),
   },


}



</script>

<style scoped>
  .contentContainer {
    display: flex;
    width: 100vw;
    justify-content: space-around;
    /* justify-content: space-between; */
    /* left: 10vw; */
    position: absolute;
    padding: 5vw 0;
  }

  .settingsContainer {
    background-color: lightgray;
    position: absolute;
    width: 80vw;
    bottom: 0vh;
    left: 10vw;
  }

  #tacho {
    position: absolute;
    bottom: 10vh;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .tachoview {
      background-image: url('../assets/Background.png');
      width: 100vw;
      height: 100vh;
      color: white;
  }

  .sensorDubugContainer {
    display: flex;
  }
  .sensorDebugDiv {
    padding: 1vw;
    border: solid black 1px;
    color: blue;
    min-width: 2em;
  }
</style>

<template>
  <div id="" class="appBG">
    <!-- <div id="headlineContainer"> 
        <h1>consilio  - add new Highscore </h1> 
    </div> -->
    <div id="logoContainer"> 
        <img src="/img/consilio/consilio_logo.png" id="consilioLogo">
    </div>

    <div id="inputViewContainer"  v-if="view === 'input'">

        <div id="inputContainer" class="inputContainer">
            <div class="inputName"> Vorname * </div>
            <input maxlength="60" name="new_vorname" type="text" 
                v-model="new_vorname" v-bind:class="{'noData' : noData_vorname }" :disabled="disableData == 1" />
            <div class="inputName"> Nachname * </div>
            <input maxlength="60" name="new_nachname" type="text" 
                v-model="new_nachname" v-bind:class="{'noData' : noData_nachname }" :disabled="disableData == 1" />  
            <div class="inputName"> Firma *  </div>
            <input maxlength="60" name="new_comp" type="text" 
                v-model="new_comp" v-bind:class="{'noData' : noData_comp }" :disabled="disableData == 1" />  
            <div class="inputName">E-Mail *  </div>
            <input maxlength="60" name="new_email" type="text" 
                v-model="new_email" v-bind:class="{'noData' : noData_email }" :disabled="disableData == 1" />  
            
            <div class="checkboxContainer">
     
                <div class="checkboxName" 
                    @click="showPdf=true"
                >  
                   Mit dem Versand des Formulars erkläre ich mich mit der Datenschutzerklärung 
                  einverstanden. *
                </div>
                <input 
                    :disabled="disableData == 1"
                    class="checkb" type="checkbox" id="datenschutz" name="datenschutz" v-model="datenschutz">
                <div class="checkboxName">
                  Bitte informieren Sie mich per E-Mail über aktuelle Veranstaltungen, Produkte, und Serviceleistungen.
                  Sie können den Empfang der E-Mails jederzeit widerrufen. *
                </div>
                <input :disabled="disableData == 1" 
                    class="checkb" type="checkbox" id="newsletter" name="newsletter" v-model="newsletter">
                   
                   <div class="checkboxName">
                      * Plichtfelder
              </div>
            </div>
        </div>
     
        <div id="buttonContainer1" class="buttonContainer">
            <div class="buttonwrapper" >
                <div class="buttonCon"  v-on:click="validateForm('tentable')">Bestätigen  </div>
            </div>
            <div class="buttonCon" id="clearButton" v-on:click="clearInput">Löschen </div>
        </div>

         <div id="inputContainer2" class="inputContainer">
            <div class="inputName">           Punkte  </div>
            <input 
                maxlength="5" name="new_score" type="number" 
               v-model="new_score" v-bind:class="{ 'noData' : noData_score }" :disabled="disablePoints == 1" />  
        </div>
        <div class="buttonContainer">
            <div class="buttonCon"  v-on:click="validateFormToSend('tentable')" :disabled="disablePoints == 1">Senden</div>
        </div>
      </div>


      <!-- ...................................... PDF ........................................ -->
      <div class="pdfContainer"
        v-if="showPdf === true" 
        @click="showPdf=false"
      >
      
      <div class="pdfIFrame">
      <img src="/img/consilio/0001.jpg" width=100%>
      <img src="/img/consilio/0002.jpg" width=100%>
      <img src="/img/consilio/0005.jpg" width=100%>
      <img src="/img/consilio/0003.jpg" width=100%>
      <img src="/img/consilio/0004.jpg" width=100%> 
      </div>

         <el-button type="" @click="showPdf=false"
          class="OKButton">OK</el-button>
      </div>

<!-- ...................................... HighScore ........................................ -->
    <div id="hsList" 
        v-if="view === 'top10'">

        <TopTen :eventName="eventName" ></TopTen>

         <el-button type="" @click="startInput()" 
          class="startButton">Jetzt teilnehmen</el-button>

    </div>

  </div>
</template>



<script>
  import {mapGetters} from "vuex";
  import { mapActions } from "vuex";
  import TopTen from '@/components/highscore/TopTen.vue'; // @ is an alias to /src
  import {  Form, FormItem } from 'element-ui';

export default {
  name: 'Consilio',

  components: {
    Form,
    FormItem,
    TopTen
  },

   data () {
    return {

      pdfUrl: '/infos/consilio_grundsaetze_der_datenverarbeitung_kunden.pdf',
      showPdf: false,

       disablePoints: 1,
        disableData: 0,
        new_score : "",
        new_vorname : "", 
        new_nachname : "",
        new_email : "",
        new_comp : "",
        noData_vorname : false,
        noData_nachname : false,
        noData_email : false,
        noData_comp : false,
        noData_score : false,
        showSettings : false,

        datenschutz: false,
        newsletter: false,

        events: [],
        scoreObj :  {
          "email": "",
          "vorname": "",
          "name": "",
          "points": 0,
          "company": ""
        },


      showTimeInput: false,
      eventName: 'consilio',
      view: 'top10',

      formClean:{
        playerName: "",
        playerVorName: "",
        email: "",
        companyName: "",
        points: 0,
      },
      formTest:{
        playerName: "asdf",
        playerVorName: "ich",
        email: "mich@nix.de",
        companyName: "ääää",
        zustimmung: false,
        points: 0,
      },
      form:{},

      rules: {
          playerName: [
            { required: true, message: 'Please input your name', trigger: 'blur' },
            { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' }
          ],
          playerVorName: [
            { required: true, message: 'Deinen Namen bitte', trigger: 'change' }
          ],
          email: [
            { type: 'email', required: true, message: 'Deine Email bitte', trigger: 'change' }
          ],
          companyName: [
            { required: true, message: 'Deinen Arbeitgeber bitte', trigger: 'change' }
          ],
      },
      timeRules: {},

    }
  },

  computed:{
    ...mapGetters([
    ]),
  },

  created() {
   // this.getHighScoreListByEvent(this.eventName)
  },

  mounted() {
  },

  methods: {

    ...mapActions([
      'sendDataToServer',
      'showHighscore',
      'getHighScoreListByEvent',
      'setShowHighscore',
    ]),

    startInput: function () {
      this.view = 'input'
      this.clearInput()
    },

        
        clearInput : function() {
          this.new_score = ""; 
          this.new_vorname = ""; 
          this.new_nachname = ""; 
          this.new_email = ""; 
          this.new_comp = ""; 
          this.disablePoints = 1;
          this.disableData = 0;
          this.newsletter = false
          this.datenschutz = false
        },

        validateForm: function(_id) {
          // do not send if form is empty 
          if  (this.new_vorname == "" || this.new_comp =="" || this.new_nachname =="" || this.new_email =="") {
            console.log("inputs nicht alle ausgefüllt"); 
            return; 
          }
          if  (this.datenschutz == false || this.newsletter == false) {
            console.log("checkboxes not checked"); 
            return; 
          }
          this.weiter(_id);
        },
        validateFormToSend: function(_id) {
          // do not send if form is empty 
          if  (this.new_score == "") {
            console.log("Score nicht ausgefüllt"); 
            return; 
          }
          this.sendData(_id);
        },

        /**
         * persönliche Daten ausgrauen 
         *  punkte aktivieren 
         */
        weiter: function(_id) {
          this.disablePoints = 0;
          this.disableData = 1;
        },

        sendData: function(_id) {
          let hsForm = {
              "points" : this.new_score,
              "email" : this.new_email,
              "playerVorName": this.new_vorname,
              "playerName": this.new_nachname,
              "companyName": this.new_comp,
              "eventName": this.eventName
          }
          this.sendDataToServer(hsForm)
          console.log("Form gesendet"); 
          this.clearInput();
        },
  },

}

</script>

<style lang="scss" scoped>
.appBG {
    background-color: black;
    color: white;
    background-image: url("/img/consilio/Highscore-Consilio_Hintergrund_hoch.png");
    background-size: cover;
    background-position: top; 
    font-family: sans-serif;
    width: 100vw;
    height: 100vh;
    position: absolute;
    padding: 0 0 0 2vh;
}

#logoContainer {
    width: 100vw;
    display: flex;
}
#consilioLogo {
    height: 5vh;
    align-content: center;
    margin: 3vh auto 5vh;
}

#headlineContainer {
    margin: 5vw;
    font-size: 2rem;
}

.input_number {
    width: 30%;
}
.input_text {
    width: 80%;
}
.inputLine  {
    display: flex;
    margin: 0 0 2vh 0;
}

input {
    padding: 1vh 2vw;
    width: 60vw; 
    font-size: 3vh; 
    margin:  0.5vh 1vw;
    background-color: white;
}

.noData {
    border: solid;
    border-color: red;
}

.inputContainer {
    display: flex;
    /* position: absolute; */
    width: 100vw;
    flex-wrap: wrap;
}
#inputContainer2 {
   margin: 1vh 0;
}

.inputName {
    width: 20vw;
    font-size: 2vh;
    text-align: left;
    padding: 2vh 0 0 2vw;
    white-space: nowrap;
}

.checkboxName {
     width: 75vw;
    font-size: 1.5vh;
    text-align: left;
    padding: 2vh 2vw;
    white-space: normal;
}


.buttonCon {
	color: black;
	background-color: white;
	padding: 2vh;
	border-radius: 0.5vw;
    margin: 2vh 2vw;
    font-weight: bold;
    text-align: center;
    font-size: 2vh;
    width: 37vw;
}
.buttoninactive {
	background-color: grey;
    color: lightgray;
}

#logIF {
    text-align: botton; 
    position: absolute;
    bottom : 0px;
    left: 0px;
    width: 50vw;
    height : 20vh; 
    margin: 3vh 2vw;
    background-color: darkgray;
    overflow-wrap: break-word;
    overflow-x: clip; 
    overflow-y: auto; 
    padding: 1rem;
}


.buttonwrapper {
        display: contents;
}

.buttonContainer{
    display: flex; 
    margin: 0 5vw;
}

.checkb{
    height: 4vh;
    width: 4vh;
    margin: 2vh;
}
.checkboxContainer {
 //   padding: 2vh 0 2vh 0;
    display: flex;
        width: 100vw;
    flex-wrap: wrap;
    margin: 2vh 0 0 0;
}



#sendHSButton {
    background-color: aquamarine;
}

#sendHSButtonM {
    background-color: #009dff;
}
#sendHSButtonF {
    background-color: #f442e5;
}



#clearButton {
    background-color: lightpink;
}

#mainContentContainer {
    position: relative;
}

#eventContainer {
        position: relative;
        display: flex;
}

#dsOverlay {
    position: fixed;
    left: 5vw;
    width: 78vw;
    top: 5vh;
    height: 75vh;
    background-color: white;
    padding: 5vw;
    z-index: 100;
    overflow: auto;
    color: black;
}

.pdfContainer {
  position: fixed;
    left: 5vw;
    top: 5vh;
    width: 90vw;
    height: 90vh;
    z-index: 10;
    display: block;


  .pdfIFrame {
    position: absolute;
    overflow: scroll;
    width: 100%;
    height: 90%;
  }

  .OKButton {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}


</style>

<template>
  <div id="versionContainer">
    
    <div v-for="(version, index) in VERSIONS" :key="index" >  
      {{version.VERSION}} 
    </div>
    
  </div>
</template>

<script>
  import axios from 'axios';
  import {mapGetters} from "vuex";
  // import { mapActions } from "vuex";

export default {
  name: 'Infos',

  computed:{
    ...mapGetters([
      'VERSIONS',
    ]),
  },

  mounted() {
  },

  methods: {
  }

}

</script>

<style scoped>

  #versionContainer {
      position: absolute;
      right: 1vw;
      text-align: right;
  }

</style>

import axios from 'axios';

const state = {
  warteListe: [],
  myDeviceId: -1,
  currentInstanceId: -1,

  // mobileApiUrl: 'http://localhost:8100/mobileapi/',
 mobileApiUrl: 'http://tentable.de:8100/mobileapi/',

};
// ============================ END STATE ===========================


// =========================== GETTERS :::::::::::::::::::::::::
const getters = {
  warteListe: state => { return state.warteListe },
};

// ================== ACTIONS =========================
const actions = {

    async getWarteListe ({ commit, state }) {
      let response = await axios.get(state.mobileApiUrl + "warteliste", state.axiosHeader)
      .then(response =>   commit("setWarteListe", response.data))
      .catch(err => { console.log(' X -> getApiVersions -> Error ', err);
      });
    },
    async removeFromWarteListe ({commit, state }, _id) {
      let response = await axios.get(state.mobileApiUrl + "removeFromWarteListe/" + _id, state.axiosHeader)
      .then(response =>   commit("setWarteListe", response.data))
      .catch(err => { console.log(' X -> getApiVersions -> Error ', err);
      });
    },
    async addToWarteSchlange ({commit, state }, _id) {
      let response = await axios.get(state.mobileApiUrl + "startFromQR/" + _id, state.axiosHeader)
      .then(response =>   commit("setWarteListe", response.data))
      .catch(err => { console.log(' X -> getApiVersions -> Error ', err);
      });
    },

};

// ================== MUTATIONS =========================
const mutations = {

  setWarteListe(state, _data) {
    state.warteListe = _data
    console.log("X -> setWarteListe : ", _data)
  },

  // set_contact_email(state, value) { state.getInTouch.officeBranch.contact_email = value },
  // set_isPM(state, value){ state.getInTouch.isPm = value },
};

export default {
  state,
  getters,
  actions,
  mutations
};
